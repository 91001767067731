var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-row",
        { attrs: { dense: "" } },
        [
          _c(
            "v-col",
            { staticClass: "d-flex", attrs: { cols: "12", sm: "4" } },
            [
              _c("v-select", {
                attrs: {
                  items: _vm.tables,
                  "item-text": "name",
                  "item-value": "value",
                  dense: "",
                  outlined: "",
                },
                model: {
                  value: _vm.activeTable,
                  callback: function ($$v) {
                    _vm.activeTable = $$v
                  },
                  expression: "activeTable",
                },
              }),
            ],
            1
          ),
          _c(
            "v-col",
            { staticClass: "d-flex", attrs: { cols: "12", sm: "4" } },
            [
              _c(
                "v-btn",
                {
                  attrs: { dark: "", color: "primary" },
                  on: { click: _vm.getSyncMap },
                },
                [_c("v-icon", [_vm._v("mdi-refresh")])],
                1
              ),
            ],
            1
          ),
          _c("v-spacer"),
          _c(
            "v-col",
            { attrs: { cols: "12", sm: "4" } },
            [
              _c(
                "v-scroll-x-transition",
                [
                  _c(
                    "v-btn",
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value: _vm.selected.length,
                          expression: "selected.length",
                        },
                      ],
                      staticClass: "float-right",
                      attrs: { dark: "", color: "red" },
                      on: { click: _vm.deleteItems },
                    },
                    [
                      _c("v-icon", [_vm._v("mdi-delete")]),
                      _vm._v(
                        " Delete " +
                          _vm._s(_vm.selected.length) +
                          " Map" +
                          _vm._s(_vm.selected.length > 1 ? "s" : "") +
                          " "
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("v-data-table", {
        staticClass: "elevation-1",
        attrs: {
          dense: "",
          headers: _vm.headers,
          items: _vm.items[_vm.activeTable],
          "item-key": "id",
          "show-select": "",
          "footer-props": {
            itemsPerPageOptions: [50, 100, 150],
          },
        },
        scopedSlots: _vm._u(
          [
            {
              key: `item.name`,
              fn: function ({ item }) {
                return [
                  _c("p", { staticClass: "mb-0" }, [
                    _vm._v(_vm._s(_vm.getItemName(item.type, item.ttId))),
                  ]),
                ]
              },
            },
            {
              key: `item.timestamp`,
              fn: function ({ item }) {
                return [
                  _c("p", { staticClass: "mb-0" }, [
                    _vm._v(
                      _vm._s(
                        _vm.format(new Date(item.timestamp * 1000), "PP p")
                      )
                    ),
                  ]),
                ]
              },
            },
          ],
          null,
          true
        ),
        model: {
          value: _vm.selected,
          callback: function ($$v) {
            _vm.selected = $$v
          },
          expression: "selected",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }