var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-toolbar-items",
    { staticClass: "w-full" },
    [
      _c(
        "v-btn",
        {
          staticClass: "nav-button",
          attrs: {
            text: "",
            color: _vm.currentPage == "logs" ? "primary" : "",
          },
          on: {
            click: function ($event) {
              return _vm.$emit("setCurrentPage", "logs")
            },
          },
        },
        [_vm._v(" Logs ")]
      ),
      _c(
        "v-btn",
        {
          staticClass: "nav-button",
          attrs: {
            text: "",
            color: _vm.currentPage == "sync-map" ? "primary" : "",
          },
          on: {
            click: function ($event) {
              return _vm.$emit("setCurrentPage", "sync-map")
            },
          },
        },
        [_vm._v(" Sync Map ")]
      ),
      _c("v-spacer"),
      _c(
        "v-btn",
        {
          staticClass: "nav-button",
          attrs: {
            text: "",
            color: _vm.currentPage == "docs" ? "primary" : "",
          },
          on: {
            click: function ($event) {
              return _vm.$emit("setCurrentPage", "docs")
            },
          },
        },
        [_vm._v(" Docs ")]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }