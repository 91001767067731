var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "v-container",
    { attrs: { fluid: "" } },
    [
      _c(
        "v-toolbar",
        {
          staticClass: "mx-6 mb-2 mt-2",
          attrs: { height: "50", elevation: "4", rounded: "" },
        },
        [
          _c("EZRSyncMenu", {
            attrs: { currentPage: _vm.currentPage },
            on: { setCurrentPage: _vm.setCurrentPage },
          }),
        ],
        1
      ),
      _c(
        "v-row",
        { staticClass: "mx-6 mt-2", attrs: { dense: "" } },
        [
          _c(
            "v-col",
            {
              staticClass: "d-flex flex-column justify-center",
              attrs: { cols: "12" },
            },
            [
              _c("Logs", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.currentPage == "logs",
                    expression: "currentPage == 'logs'",
                  },
                ],
              }),
              _c("SyncMap", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.currentPage == "sync-map",
                    expression: "currentPage == 'sync-map'",
                  },
                ],
              }),
              _c("Docs", {
                directives: [
                  {
                    name: "show",
                    rawName: "v-show",
                    value: _vm.currentPage == "docs",
                    expression: "currentPage == 'docs'",
                  },
                ],
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }